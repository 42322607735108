/*
*慢病管理-患者管理接口
*/

import request from '@/utils/request'

// 套餐包列表
export function getPatientList(params) {
  return request({
    url: '/admin/patient/patientList',
    method: 'GET',
    params
  })
}


// 医师列表(已配置服务包)
export function getDoctorDemandList(params) {
  return request({
    url: '/admin/patient/doctorDemandList',
    method: 'GET',
    params
  })
}

// 服务包列表, 传入有服务包医生的id
export function getDemandList(id, params) {
  return request({
    url: `/admin/patient/demandList/${id}`,
    method: 'GET',
    params
  })
}

// 发放服务包
export function postPatientSendDemand(id, data) {
  return request({
    url: `/admin/patient/sendDemand/${id}`,
    method: 'POST',
    data
  })
}

// 导入患者档案
export function postPatientImport(data) {
  return request({
    url: `/admin/patient/import`,
    method: 'POST',
    // headers:{'Content-Type':'multipart/form-data'},
    data
  })
}

// 同步数据
export function postPatientSyncContract(data) {
  return request({
    url: `/admin/patient/syncContract`,
    method: 'POST',
    data
  })
}
